import { gettext } from "django-i18n";

import React from "react";
import PropTypes from "prop-types";
import { FixedSizeList } from "react-window";

import clsx from "clsx";
import { debounce, flatMap, groupBy, isEmpty, map, pickBy } from "lodash";

import Popover from "@deprecated/material-ui/Popover";
import ClearIcon from "@deprecated/material-ui/svg-icons/navigation/cancel";
import SearchIcon from "@deprecated/material-ui/svg-icons/action/search";

import Tooltipify from "_common/components/tooltipify";
import IconButton from "./buttons/icon";
import TextField from "./TextField";

const OPTION_HEIGHT = 34;

/**
 * Field component that can select one item from a list of items.
 */
class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      width: "100%",
      selectIndex: 0,
      keyboardSelectionIndex: 0,
      term: "",
      lastGoodTerm: "",
      options: [],
      value: null,
      pristine: true,
      showMenuAbove: false,
    };

    this.onTermChange = debounce(this.onTermChange, 300, {
      leading: false,
    });

    this.textInputRef = React.createRef();
    this.autocompleteMenuRef = React.createRef();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setInitialValue();
  }

  componentDidMount() {
    window.addEventListener("resize", this.adjustPopoverMenu.bind(this));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // If the options are set from the outside, keep the state in sync!
    const options = this.getOptions();

    this.setState({
      options,
    });

    // If we have a controlled autocomplete we'll need to keep the value in sync
    if (nextProps.value !== this.props.value) {
      this.updateValue(nextProps.value, nextProps.options);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustPopoverMenu.bind(this));
  }

  get hasIcon() {
    const { clearable, hideIcon } = this.props;
    return clearable || !hideIcon;
  }

  // Helps update the internal value
  updateValue(newValue = "", newOptions = []) {
    const optionIndex = newOptions.findIndex(
      (option) => option.value === newValue
    );
    const option = newOptions[optionIndex];

    let label = newValue;

    if (option && option.label) {
      label = option.label;
    }

    this.setState({
      value: newValue,
      term: label,
      lastGoodTerm: label,
      selectIndex: optionIndex,
    });
  }

  // We use this to set the displayed initial value
  // on UNSAFE_componentWillMount. Prefers initialValue over value
  setInitialValue() {
    const { initialValue, value } = this.props;

    const usableValue = initialValue || value;

    if (!usableValue) {
      return;
    }

    this.updateValue(usableValue, this.props.options);
  }

  getAnchorEl() {
    const component = this.textInputRef.current;

    if (!component) {
      return null;
    }

    return (
      (component.input.inputRef && component.input.inputRef.current) ||
      component.input
    );
  }

  adjustPopoverMenu() {
    // Compute whether the popover will extend beyond the page bottom and
    // display the popover above if there is not enough available space
    // TODO: Upgrade Material-UI to avoid requiring this work-around
    if (!this.textInputRef.current) {
      return;
    }

    const anchorEl = this.getAnchorEl();
    const autocompleteMenuEl = this.autocompleteMenuRef.current;

    const { bottom, width } = anchorEl.getBoundingClientRect();

    if (autocompleteMenuEl) {
      const componentHeight = autocompleteMenuEl.clientHeight;
      const availableHeight = window.innerHeight - bottom;

      this.setState({
        showMenuAbove: componentHeight > availableHeight,
      });
    }

    this.setState({
      width: width + (this.hasIcon ? 30 : 0),
    });
  }

  openMenu() {
    this.setState({
      open: true,
      pristine: false,
    });

    // The display needs to be adjusted after everything computes!
    setTimeout(() => this.adjustPopoverMenu(), 0);
  }

  closeMenu() {
    const { lastGoodTerm } = this.state;

    this.setState({
      open: false,
      term: lastGoodTerm,
    });
  }

  clear(propagate = false) {
    const { onChange } = this.props;

    this.setState({
      term: "",
      value: "",
      lastGoodTerm: "",
      options: [],
    });

    propagate && onChange && onChange(null);
  }

  moveSelection(moveUp = false) {
    const { keyboardSelectionIndex } = this.state;

    this.setState((prevState) => {
      const { pristine } = prevState;

      if (pristine) {
        return { keyboardSelectionIndex: 0 };
      }

      const addDirection = moveUp ? -1 : 1;
      return { keyboardSelectionIndex: keyboardSelectionIndex + addDirection };
    });
  }

  pickOption(pickedOptionIndex) {
    const { keyboardSelectionIndex, options } = this.state;
    const { onChange, clearOnSelection } = this.props;

    const activeIndex = pickedOptionIndex || keyboardSelectionIndex;
    const option = options[activeIndex];

    if (!option) {
      return;
    }

    // Display group name in the textbox if applicable
    const label = option.group
      ? `${option.group} — ${option.label}`
      : option.label;

    if (clearOnSelection) {
      this.clear();
    } else {
      this.setState({
        term: label,
        lastGoodTerm: label,
        value: option.value,
        selectIndex: pickedOptionIndex,
        keyboardSelectionIndex: 0,
      });
    }

    onChange && onChange(option.value, option);

    setTimeout(() => this.closeMenu(), 0);
  }

  filterOptions(options = [], term = "") {
    const needle = term.trim();

    if (isEmpty(needle)) {
      return options;
    }

    const pickedValue = this.state.value;

    // Omit currently selected item
    const items = options.filter((option) => {
      const { label, value } = option;

      if (!label || (pickedValue === value && needle === label)) {
        return false;
      }

      return true;
    });

    return items.filter((item) => {
      const { label = "", group = "" } = item;

      const haystack = label + group;

      // a search may contain multiple terms
      const tokens = needle.toLowerCase().split(" ");

      return tokens.every(
        (token) => haystack.toLowerCase().indexOf(token) > -1
      );
    });
  }

  getOptions(term) {
    const { onTermChange, options, maxOptions } = this.props;

    let processedOptions = options;

    if (!onTermChange) {
      processedOptions = this.filterOptions(options, term);
    }

    if (this.shouldGroupOptions(processedOptions)) {
      const groupedOptions = this.groupOptions(processedOptions);

      // We need to ensure internal indexing matches what the user is seeing
      processedOptions = flatMap(groupedOptions, (options) => options);
    }

    return processedOptions.slice(0, maxOptions);
  }

  groupOptions(options) {
    return groupBy(options, (option) => option.group);
  }

  shouldGroupOptions(options) {
    return options.every((option) => option.group);
  }

  onTermChange(term) {
    const { onTermChange } = this.props;

    onTermChange && onTermChange(term);
  }

  handleInputChange(e) {
    const term = e.target.value;

    const options = this.getOptions(term);

    this.setState({
      term,
      options,
    });

    this.onTermChange(term);

    this.openMenu();
  }

  handleInputKeyUp(e) {
    // Escape
    if (e.which !== 27) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.closeMenu();

    this.textInputRef.current.blur();
  }

  handleInputKeyPress(e) {
    // Enter
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  }

  handleInputKeyDown(e) {
    const action = {
      // up
      38: this.moveSelection.bind(this, true),
      // down
      40: this.moveSelection.bind(this),
      // enter
      13: this.pickOption.bind(this),
      // tab
      9: this.pickOption.bind(this),
    }[e.which.toString()];

    if (!this.state.open || !action) {
      return;
    }

    e.preventDefault();
    action();
  }

  handleInputBlur() {
    const { options } = this.state;

    if (isEmpty(options)) {
      this.closeMenu();
    }
  }

  handleInputClick() {
    const { onClick } = this.props;
    const term = "";

    const options = this.getOptions(term);

    this.setState({
      term,
      options,
      pristine: false,
    });

    if (onClick) {
      onClick();
    }

    this.onTermChange(term);

    this.openMenu();
  }

  handleOptionClick(i) {
    this.pickOption(i);
  }

  handleRequestClose() {
    this.closeMenu();
  }

  renderInput() {
    const { term, value } = this.state;

    const {
      autoFocus,
      clearable,
      disabled,
      hideIcon,
      id,
      errorText,
      floatingLabelText,
      fullWidth,
      hintText,
      customOnBlur,
      multiLine,
      name,
    } = this.props;

    const showClearButton = clearable && value;
    const inputClasses = clsx(
      "sde-auto-complete-ellipses",
      "sde-auto-complete-search"
    );
    const autocompleteRef = React.createRef();
    const isIE = /*@cc_on!@*/ false || !!document.documentMode; // eslint-disable-line spaced-comment

    const extraInputProps = pickBy(
      this.props,
      (value, key) => key.startsWith("aria-") || key.startsWith("data-")
    );

    return (
      <div className={inputClasses}>
        <input
          type="hidden"
          name={name}
          value={value || ""}
          ref={autocompleteRef}
        />
        <TextField
          {...extraInputProps}
          name={`${name}-textfield`}
          id={id}
          data-cy="auto-complete-search"
          ref={this.textInputRef}
          fullWidth={fullWidth}
          multiLine={multiLine}
          autoComplete="off"
          autoFocus={autoFocus}
          disabled={disabled}
          value={term || ""}
          floatingLabelText={floatingLabelText}
          hintText={hintText}
          errorText={errorText}
          tooltip={term}
          style={{ paddingRight: this.hasIcon ? "30px" : undefined }}
          title={(isIE && term) || ""}
          readOnly={document.activeElement !== autocompleteRef.current && isIE}
          onChange={this.handleInputChange.bind(this)}
          onKeyDown={this.handleInputKeyDown.bind(this)}
          onKeyUp={this.handleInputKeyUp.bind(this)}
          onKeyPress={this.handleInputKeyPress.bind(this)}
          onBlur={customOnBlur || this.handleInputBlur.bind(this)}
          onClick={this.handleInputClick.bind(this)}
        />
        {showClearButton && (
          <IconButton
            className="sde-auto-complete-clear"
            style={{
              width: null,
              height: null,
              position: null,
            }}
            onClick={() => this.clear(true)}
          >
            <ClearIcon />
          </IconButton>
        )}
        {!showClearButton && !hideIcon && (
          <SearchIcon
            className="sde-auto-complete-search-icon"
            style={{
              top: floatingLabelText ? "37px" : "12px",
              color:
                (disabled && "rgba(0, 0, 0, 0.26)") || "rgba(0, 0, 0, 0.54)",
            }}
          />
        )}
      </div>
    );
  }

  renderOption(option, index, style = {}) {
    const { renderOption, showTooltip } = this.props;
    const { selectIndex, keyboardSelectionIndex } = this.state;

    const { label, value } = option;
    const isKeyboardSelected = keyboardSelectionIndex === index;
    const classes = clsx("sde-auto-complete-option", {
      "is-selected": renderOption ? false : selectIndex === index,
      "is-hovered": isKeyboardSelected,
    });

    // we added another parent div to handle key for the list and events
    // the inner div will be what tooltip uses for positioning and text overflow check
    return (
      <div
        tabIndex="0"
        aria-selected={selectIndex === index}
        role="option"
        key={value}
        onMouseDown={this.handleOptionClick.bind(this, index)}
        style={style}
      >
        <Tooltipify showOnEllipsis message={showTooltip && label}>
          <div className={classes}>
            {(renderOption && renderOption(option)) || label}
          </div>
        </Tooltipify>
      </div>
    );
  }

  renderOptionGroup(name, options) {
    const { renderOptionGroupTitle } = this.props;

    const title =
      (renderOptionGroupTitle && renderOptionGroupTitle(name)) || name;

    return (
      <div key={name} className="sde-auto-complete-group">
        <div className="sde-auto-complete-group-title">{title}</div>
        <div className="sde-auto-complete-group-options">{options}</div>
      </div>
    );
  }

  renderOptions() {
    const { options } = this.state;
    const { lazilyRenderOptions, maxHeight } = this.props;

    const shouldGroup = this.shouldGroupOptions(options);

    if (shouldGroup) {
      const groupedOptions = this.groupOptions(options);

      return map(groupedOptions, (options, key) =>
        this.renderOptionGroup(
          key,
          options.map((option, index) => this.renderOption(option, index))
        )
      );
    }

    if (lazilyRenderOptions) {
      const listHeight =
        options.length < maxHeight
          ? OPTION_HEIGHT * options.length
          : OPTION_HEIGHT * maxHeight - 10; // Subtract 10 to get rid of the extra scrollbar

      return (
        <FixedSizeList
          height={listHeight}
          itemCount={options.length}
          itemSize={OPTION_HEIGHT}
          width="100%"
        >
          {({ index, style }) =>
            this.renderOption(options[index], index, style)
          }
        </FixedSizeList>
      );
    }

    return options.map((option, index) => this.renderOption(option, index));
  }

  getDropdownStyle() {
    const { maxHeight, maxOptions } = this.props;
    const style = {};

    if (maxHeight <= maxOptions) {
      // Make the dropdown scrollable
      style.maxHeight = OPTION_HEIGHT * (maxHeight + 0.5);
    }

    return style;
  }

  renderMenu() {
    const { open, options, pristine, showMenuAbove, width } = this.state;
    const { multiLine, searchHeader } = this.props;

    const anchorEl = this.getAnchorEl();

    if (!anchorEl) {
      return null;
    }

    let marginTop;
    if (multiLine) {
      marginTop = showMenuAbove ? "-6px" : "6px";
    } else {
      marginTop = showMenuAbove ? "0px" : "-20px";
    }

    return (
      <Popover
        open={open && !isEmpty(options) && !pristine}
        anchorEl={anchorEl}
        style={{ width, marginTop }}
        anchorOrigin={{
          horizontal: "left",
          vertical: showMenuAbove ? "top" : "bottom",
        }}
        targetOrigin={{
          horizontal: "left",
          vertical: showMenuAbove ? "bottom" : "top",
        }}
        useLayerForClickAway
        onRequestClose={this.handleRequestClose.bind(this)}
        canAutoPosition={false}
      >
        <div
          role="menu"
          className="sde-auto-complete-menu"
          data-cy="auto-complete-menu"
          ref={this.autocompleteMenuRef}
          style={this.getDropdownStyle()}
        >
          <div className="sde-auto-complete-search-header">{searchHeader}</div>
          {this.renderOptions()}
        </div>
      </Popover>
    );
  }

  render() {
    const { style } = this.props;

    return (
      <div className="sde-auto-complete" style={style}>
        {this.renderInput()}
        {this.renderMenu()}
      </div>
    );
  }
}

AutoComplete.propTypes = {
  searchHeader: PropTypes.string,
  options: PropTypes.array,
  maxHeight: PropTypes.number, // The max number of options that should display in the dropdown
  maxOptions: PropTypes.number, // The max number of options in total
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  floatingLabelText: PropTypes.string,
  hintText: PropTypes.string,
  id: PropTypes.string,
  errorText: PropTypes.string,
  clearOnSelection: PropTypes.bool,
  lazilyRenderOptions: PropTypes.bool,
  onTermChange: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  renderOptionGroupTitle: PropTypes.func,
  renderOption: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customOnBlur: PropTypes.func,
};

AutoComplete.defaultProps = {
  searchHeader: gettext("Search suggestions"),
  value: "",
  options: [],
  maxHeight: 9,
  maxOptions: 5,
  autoFocus: false,
  clearable: false,
  disabled: false,
  floatingLabelText: gettext("Select an option..."),
  fullWidth: true,
  multiLine: false,
  hideIcon: false,
  clearOnSelection: false,
  lazilyRenderOptions: false,
  style: {},
};

export default AutoComplete;
